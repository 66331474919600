.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width: 100%; 
  height: 100%;
  outline: none;
}

.cross-cursor {
  cursor: crosshair !important;
}

.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated { will-change:auto !important; }

.polyline-blue{
  stroke: #1976d2;
  fill: none;
}
.polyline-violet {
  stroke: #9c27b0;
  fill: none;
}
.polyline-orange {
  stroke: #ED6C02;
  fill: none;
}
.polyline-pink {
  stroke: #d81b60;
  fill: none;
}
.polyline-green {
  stroke: #7cb342;
  fill: none;
}


.stroke-dasharray-2{
  stroke-dasharray: 15,19;
}
.stroke-dasharray-3{
  stroke-dasharray: 15,36;
}
.stroke-dasharray-4{
  stroke-dasharray: 15,53;
}
.stroke-dasharray-5{
  stroke-dasharray: 15,70;
}

.stroke-dashoffset-1{
  stroke-dashoffset: 0;
}
.stroke-dashoffset-2{
  stroke-dashoffset: 17;
}
.stroke-dashoffset-3{
  stroke-dashoffset: 34;
}
.stroke-dashoffset-4{
  stroke-dashoffset: 51;
}
.stroke-dashoffset-5{
  stroke-dashoffset: 68;
}


.leaflet-control-zoom.leaflet-bar.leaflet-control {
  margin-bottom: 30px
}

.center-screen {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
 }

 .custom-icon {
    margin-left: 2px;
 }