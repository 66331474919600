body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.marker-small{
  width: 1rem;
  height: 1rem;
  display: block;
  left: -1.05rem;
  top: -0.1rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
}

.text-small{
  left: 0.05rem;
  top: 0.3rem;
  position: relative;
  transform: rotate(-45deg);
  text-align: center;
  color:white;
  font-size: 0.5em;
}

.marker-big{
  width: 1.7rem;
  height: 1.7rem;
  display: block;
  left: -1.45rem;
  top: -0.9rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
}

.text-big{
  left: 0.05rem;
  top: 0.5rem;
  position: relative;
  transform: rotate(-45deg);
  text-align: center;
  color:white;
  font-size: 0.8em;
}

.marker-normal{
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -1.05rem;
  top: -0.1rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
}

.text-normal{
  left: -0.01rem;
  top: 0.4rem;
  position: relative;
  transform: rotate(-45deg);
  text-align: center;
  color:white;
  font-size: 0.7em;
}
